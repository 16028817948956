import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-stack mb-2" }
const _hoisted_2 = { class: "text-muted me-2 fw-bolder" }
const _hoisted_3 = { class: "progress h-6px w-100" }
const _hoisted_4 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, "Progress " + _toDisplayString(_ctx.percent) + "%", 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "progress-bar bg-success",
        role: "progressbar",
        style: _normalizeStyle({width: _ctx.percent +'%'}),
        "aria-valuenow": _ctx.percent,
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, null, 12, _hoisted_4)
    ])
  ], 64))
}