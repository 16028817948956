
import {computed, defineComponent, onBeforeMount, onMounted} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import MyMetrics from "@/views/home/MyMetrics.vue";
import TodayTasks from "@/views/home/TodayTasks.vue";
import {useHomePageStore} from "@/store/homepage.store";
import router from "@/router";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import HomeTraining from "@/views/home/HomeTraining.vue";
import OptOut from "@/components/base/OptOut.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "Home",
  components: { HelpEmbed, OptOut, HomeTraining, TodayTasks, MyMetrics,},
  setup() {
    const user = computed(() => store.getters.currentUser);
    onBeforeMount(() => {
      router.replace(`/` + user.value.currentCompanyId + '/home')
    })
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Welcome", [])
    })
    const homePageStore = useHomePageStore();
    homePageStore.getTodayWork();
    const work = computed(() => homePageStore.today);
    return {
      work
    }
  },
  methods: {
    test() {
      store.dispatch(Actions.LOAD_PROFILE)
    }
  }
})
