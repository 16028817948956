
import {defineComponent, PropType} from "vue";
import {GetSpaceId} from "@/core/composite/composite";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TrainingUserStatus from "@/views/training/TrainingUserStatus.vue";
import ProgressStatus from "@/views/training/ProgressStatus.vue";

export default defineComponent({
  name: "HomeTraining",
  components: {ProgressStatus, TrainingUserStatus, DateTimeFormat},
  props: {
    trainings: {type: Array as PropType<any>}
  },
  setup() {
    return {
      ...GetSpaceId(),
    }
  }
})
