
import {defineComponent, ref, watch} from "vue";
import DocUserService from "@/core/services/DocUserService";

export default defineComponent({
  name: "ProgressStatus",
  props: {
    trainingId: {type: String}
  },
  setup(props) {
    const percent = ref(0)
    const loadProgress = (id) => {
      DocUserService.countProgress(id).then(res => {
        percent.value = res.percent;
      })
    }
    loadProgress(props.trainingId);
    watch(() => props.trainingId, cb => {
      loadProgress(cb);
    })

    return {
      percent,
    }
  }
})
