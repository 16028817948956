
import {defineComponent, PropType, ref} from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {GetMetricFormName, LoadPanel} from "@/core/composite/composite";
import MetricValueFormat from "@/views/metric/value/MetricValueFormat.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import MetricValueUpdateForm from "@/views/metric/MetricValueUpdateForm.vue";
import {useHomePageStore} from "@/store/homepage.store";
import UserLink from "@/views/user/UserLink.vue";

export default defineComponent({
  name: "MyMetrics",
  components: {UserLink, MetricValueUpdateForm, BaseModal, MetricValueFormat, DateTimeFormat},
  props: {
    metrics: {type: Array as PropType<any>}
  },
  setup() {
    const state = ref({id: '', value: {amount: ''}});
    const homePageStore = useHomePageStore();
    return {
      homePageStore,
      state,
      ...LoadPanel(),
      ...GetMetricFormName(),
    }
  },
  methods: {
    update(row) {
      this.state.id = row.id;
      this.state.value.amount = row.amount;
      const base = this.$refs.metricUpdateValueListRef as typeof BaseModal;
      base.showBaseModal();
    },
    onSave() {
      const base = this.$refs.metricUpdateValueListRef as typeof BaseModal;
      base.hideBaseModal();
      this.homePageStore.removeMetric(this.state.id);
    },
  }
})
