
import {defineComponent, PropType} from "vue";
import TaskName from "@/views/task/TaskName.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";
import UserLink from "@/views/user/UserLink.vue";

export default defineComponent({
  name: "TodayTasks",
  components: {UserLink, TaskDueDate, TaskName},
  props: {
    tasks: {type: Array as PropType<any>}
  }
})
